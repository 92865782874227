import { isBoolean, isNumber, isSymbol, isDate, isArray, isObject, isUndefined, isNull } from "lodash"
import Cookies from 'js-cookie'

export const useStorage = (type) => {
	const storage = type === 'local' ? localStorage : sessionStorage
	
	return {
		set(key, value) {
			let obj = { type: 'string', value }
			
			if(isBoolean(value)) {
				obj['type'] = 'boolean'
			}
			else if(isNumber(value)) {
				obj['type'] = 'number'
			}
			else if(isDate(value)) {
				obj['type'] = 'date'
			}
			else if(isArray(value)) {
				obj['type'] = 'array'
				obj['value'] = JSON.stringify(value)
			}
			else if(isObject(value)) {
				obj['type'] = 'object'
				obj['value'] = JSON.stringify(value)
			}
			
			storage.setItem(key, JSON.stringify(obj))
		},
		// Returns the current value associated with the given key, or null if the given key does not exist.
		get(key) {
			const str = storage.getItem(key) || '{}'
			// 如果是对象字符串，需要进行转换
			if(str.charAt(0) === '{' && str.charAt(str.length - 1) === '}') {
				const obj = JSON.parse(str)
				
				switch(obj.type) {
					case 'number': return Number(obj['value'])
					case 'boolean': return Boolean(obj['value'])
					case 'date': return new Date(obj['value'])
					case 'array': return JSON.parse(obj['value'])
					case 'object': return JSON.parse(obj['value'])
					default: return obj['value']
				}
			}
			
			return str
		},
		// Removes the key/value pair with the given key, if a key/value pair with the given key exists.
		remove(key) {
			storage.removeItem(key)
		},
		// Removes all key/value pairs, if there are any.
		clear() {
			storage.clear()
		}
	}
}

export const useCookie = (domain = null, path = null) => {
	const cookieOpts = { domain, path }
	
	return {
		set(key, value, expires = null) {
			if(expires) {
				cookieOpts['expires'] = expires
			}
			
			let obj = { type: 'string', value }
			
			if(isBoolean(value)) {
				obj['type'] = 'boolean'
			}
			else if(isNumber(value)) {
				obj['type'] = 'number'
			}
			else if(isDate(value)) {
				obj['type'] = 'date'
			}
			else if(isArray(value)) {
				obj['type'] = 'array'
				obj['value'] = JSON.stringify(value)
			}
			else if(isObject(value)) {
				obj['type'] = 'object'
				obj['value'] = JSON.stringify(value)
			}
			
			Cookies.set(key, JSON.stringify(obj), cookieOpts)
		},
		get(key) {
			const str = Cookies.get(key) || '{}'
			
			// 如果是对象字符串，需要进行转换
			if(str.charAt(0) === '{' && str.charAt(str.length - 1) === '}') {
				const obj = JSON.parse(str)
				
				switch(obj.type) {
					case 'number': return Number(obj['value'])
					case 'boolean': return Boolean(obj['value'])
					case 'date': return new Date(obj['value'])
					case 'array': return JSON.parse(obj['value'])
					case 'object': return JSON.parse(obj['value'])
					default: return obj['value']
				}
			}
			
			return str
		},
		remove(key) {	
			Cookies.remove(key, cookieOpts)
		},
		clear() {
			for(let key in Cookies.get()) {
				Cookies.remove(key, cookieOpts)
			}
		}
	}
}
