<template> 
<div class="vu-breadcrumb-wrapper">
	<el-icon class="nav-icon-home"><HomeFilled /></el-icon>
    <el-breadcrumb class="vu-breadcrumb">
        <el-breadcrumb-item 
            :to="item.path" 
            v-for="(item, index) in breadcrumbList" 
            :key="`breadcrumb-${index}`"> 
				{{item.label}}
        </el-breadcrumb-item>
    </el-breadcrumb> 
</div>
</template>

<script setup>
    const router = useRouter() 
    const route = useRoute()
    const breadcrumbList = ref([])
    
    const getBreadcrumbList = (r) => {
        breadcrumbList.value = []
        const matchedRoute = router.resolve({ path: r.path })
        
        const { matched } = matchedRoute
        matched.forEach((item, index) => {
            if(item.meta.title) {
                breadcrumbList.value.push({
                    path: ((index < matched.length - 1) && !item.meta.noPage) ? item.path : '',
                    label: item.meta.title
                })
            }
        })
        
        breadcrumbList.value.unshift({ path: breadcrumbList.length === 0 ? '' : { path: '/' }, label: '首页' })
    }
    
    watch(route, getBreadcrumbList, {
        immediate: true
    })
    
</script>

<style lang="scss" scoped>
	.vu-breadcrumb-wrapper {
		display: flex;
		align-items: center;
		
		.nav-icon-home {
			color: var(--el-text-color-primary);
			margin-right: 3px;
		}
		
		.vu-breadcrumb {
			.vu-breadcrumb__item {
				display: flex;
				align-items: center;
			}
		}
	}
</style>