<template>
	<div class="page-header__top">
		<div class="page-header__top-main vu-top-menu">
			<el-button class="vu-top-menu__item" link :icon="collapsed?'Expand':'Fold'" @click="handleMenuSwitch"></el-button>
			<Breadcrumb />
		</div>
		<div class="page-header__top-navi vu-top-menu">
			<el-button class="vu-top-menu__item" link icon="BrushFilled"></el-button>
			<el-button class="vu-top-menu__item" link icon="FullScreen"></el-button>
			<el-badge  class="vu-top-menu__item notify" :value="1" :show-zero="false" :offset="[-15,15]">
				<el-button link icon="Bell"></el-button>
			</el-badge>
			<el-dropdown class="vu-top-menu__group" 
				@command="handleCommand"
				@visible-change="handleProfileVisibleChange">
				<span class="username">
					<el-avatar size="small"></el-avatar>
					<el-button link :icon="profileIcon">管理员</el-button>
				 </span>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="profile" icon="User">个人中心</el-dropdown-item>
						<el-dropdown-item command="change_pwd" icon="Lock">修改密码</el-dropdown-item>
						<el-dropdown-item command="logs" icon="Document">操作日志</el-dropdown-item>
						<el-dropdown-item icon="SwitchButton" :divided="true">退出</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
			
		</div>
	</div>
</template>

<script setup>
	import Breadcrumb from './Breadcrumb.vue'
	
	const router = useRouter()
	
	const props = defineProps({
		collapsed: { type: Boolean, default: false }
	})
	
	const emits = defineEmits(['menuSwitch'])
	
	const profileIcon = ref('ArrowDown')
	
	const handleMenuSwitch = () => {
		emits('menuSwitch')
	}
	
	const handleCommand = (cmd) => {
		router.push(`/system/${cmd}`)
	}
	
	const handleProfileVisibleChange = (v) => {
		profileIcon.value = v ? 'ArrowDown':'ArrowUp'
	}
</script>

<style lang="scss" scoped>
	.page-header__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: var(--vu-header-top-height);

		.page-header__top-main {
			display: flex;
			align-items: center;
		}

		.page-header__top-navi {
			display: flex;
			align-items: center;
			justify-content: end;
		}
		
		.vu-top-menu {
			height: 100%;
			align-items: stretch;
			margin-right: 1rem;
			
			.vu-top-menu__item {
				border-radius: 0;
				margin-left: 0;
				padding: 0 .5rem;
				
				&.notify {
					display: flex;
					align-items: center;
				}
				
				:deep(.el-icon) {
					font-size: 21px;
				}
			}
			.vu-top-menu__item:hover {
				background-color: var(--vt-c-blue-white);
			}
			
			.vu-top-menu__group {
				align-items: center;
				margin-left: .5rem;
				
				.username {
					display: flex;
					align-items: center;
					
					&:focus-visible {
						outline: none;
					}
					
					.el-button {
						flex-direction: row-reverse;
					}
				}
			}
		}
	}
</style>