export default {
	error: {
		require: '必填',
		invalidEmail: '错误的Email'
	},
	home: {
		title: '控制面板',
	},
	login: {
		title: "渠道登录",
		submitButtonText: "登录",
		label: { 
			email: 'Email',
			username: '用户名',
			password: '密码',
			vcode: '验证码'
		},
		placeholder: {
			email: 'Email',
			username: '请输入用户名',
			password: '请输入密码',
			vcode: '请输入验证码'
		},
		forgot: {
			title: '忘记密码',
			buttonText: '找回密码'
		},
		register: {
			title: '注册渠道',
			buttonText: '提交注册'
		}
	}
}