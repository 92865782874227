export const useMenuList = () => {
	return [
		{ 
			name: 'vu-admin__dashboard',
			path: '/home',
			icon: 'House',
			title: '{home.title}',
			view: 'views/Index.vue'
		},
		{
			name: 'vu-admin_channel', 
			path: '/channel',
			icon: 'Memo',
			title: '渠道管理',
			children: [
				{ 
					name: 'vu-admin_channel_list', 
					path: 'list', 
					view: 'views/channel/Index.vue',
					title: '渠道列表'
				},
				{
					name: 'vu-admin_channel_order', 
					path: 'order', 
					//view: 'views/channel/Order.vue',
					title: '订单管理',
					children: [
						{ 
							name: 'vu-admin_channel_order-list',
							path: 'list',
							title: '订单列表',
							view: 'views/channel/Order.vue'
						},{ 
							name: 'vu-admin_channel_order-create',
							path: 'create',
							title: '新建订单',
							view: 'views/channel/Detail.vue'
						}
					]
				},
				{
					name: 'vu-admin_channel_income', 
					path: 'income', 
					view: 'views/channel/Income.vue',
					title: '收益列表'
				}
			]
		},
		{
			name: 'vu-admin__login',
			path: '/login',
			title: '{login.title}',
			view: 'views/Login.vue',
			single: true
		},
		{ 
			name: 'vu-admin__system',
			path: '/system',
			title: '系统设置',
			system: true,
			children: [
				{
					name: 'vu-admin__system_profile',
					path: 'profile',
					title: '个人中心',
					view: 'views/system/user/Index.vue',
				},
				{
					name: 'vu-admin__system_chg_pwd',
					path: 'change_pwd',
					title: '修改密码',
					view: 'views/system/user/ChangePwd.vue',
				},
				{
					name: 'vu-admin__system_logs',
					path: 'logs',
					title: '操作日志',
					view: 'views/system/user/Logs.vue',
				}
			]
		}
	]
}