<template>
	<div class="page__aside-logo">
		<a href="/">
			<img class="app-logo" src="@/assets/logo.svg" />
		</a>
		<a class="app-name" href="/">{{appName}}</a>
	</div>
	<el-scrollbar class="page__aside-menu">
		<Menu :collapsed="collapsed" :data="menuList"></Menu>
	</el-scrollbar>
</template>

<script setup>
	import { isUndefined } from 'lodash'
	import { useMenuList } from '@/router/menuList.js'
	
	import Menu from './Menu.vue'
	
	const props = defineProps({
		collapsed: { type: Boolean, default: false },
		data: { type: Array, default: () => ([]) }
	})
	
	const appName = ref(import.meta.env.VITE_APP_NAME)
	
	const menuList = ref([])
	menuList.value = useMenuList().filter(menu => {
		return !menu.single && !menu.system
	})
</script>

<style lang="scss" scoped>
	.page__aside-logo {
		display: flex;
		align-items: center;
		height: var(--el-header-height);
		padding: 0 .5rem;
		
		.app-logo {
			display: block;
			max-height: var(--el-header-height);
		}
		.app-name {
			color: var(--vt-bg-black);
			font-size: 19px;
			font-weight: bold;
			margin-left: .5rem;
			text-decoration: none;
		}
	}
	
	.page__aside-menu {
		height: calc( 100vh - var(--el-header-height) - 0px);
	}
</style>