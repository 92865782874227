import './assets/css/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'

const app = createApp(App)

app.use(createPinia())

import I18N from './plugins/i18n.js'
app.use(I18N)

import router from './router'
import './router/permission.js'
app.use(router)

// 引用自定义组件库
import Components from './plugins/components.js'
// 应用自定义组件库
app.use(Components)

import Prototypes from './plugins/prototypes.js'
app.use(Prototypes)

import Directive from './plugins/directive.js'
app.use(Directive)

app.mount('#app')
