import { createRouter, createWebHistory } from 'vue-router'

import Layout from '@/layout/Layout.vue'
import { useMenuList } from './menuList'
import { isUndefined } from 'lodash'

// 取到 /pages 目录下的所有的 .vue 文件
const modules = import.meta.glob('/src/views/**/*.vue')

const buildRouteItem = (menu) => {
	const { path, name, view, title } = menu
	return {
		path,
		name,
		component: modules[`/src/${view}`],
		meta: { title }
	}
}

const buildRoutes = (menuList, result, basePath) => {
	menuList.forEach(menu => {
		const { path, children } = menu
		// 如果有子菜单
		if(children && children.length > 0) {
			const menuItem = buildRouteItem(menu)
			menuItem['redirect'] = basePath + '/' + path + '/' + menu['children'][0].path
			menuItem['children'] = []
			result.push(menuItem)
			buildRoutes(children, menuItem['children'], basePath + '/' + path)
		}
		else {
			result.push(buildRouteItem(menu))
		}
	})
	
	return result
}

const createRoutes = (defaultPath = '/home') => {
	const menuList = useMenuList()
	
	const routeTable = []
	
	const inheritedMenus = menuList.filter(menu => {
		return !menu.single && !menu['children'] // 所有不是独立页面也没有子项目的菜单
	})
	const singleMenus = menuList.filter(menu => {
		return menu.single // 独立页面菜单
	})
	const levelMenus = menuList.filter(menu => {
		return menu['children'] // 有子项目的菜单
	})
	
	routeTable.push({
		path: '/',
		name: 'vu-admin_root',
		component: Layout,
		redirect: defaultPath,
		children: inheritedMenus.map(menu => {
			return buildRouteItem(menu)
		})
	})
	
	singleMenus.forEach(menu => {
		routeTable.push(buildRouteItem(menu))
	})
	
	levelMenus.forEach(menu => {
		const route = buildRouteItem(menu)
		route['component'] = Layout
		route['redirect'] = menu.path + '/' + menu['children'][0].path
		route['children'] = buildRoutes(menu['children'], [], menu['path'])
		routeTable.push(route)
	})
	
	return routeTable
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: createRoutes()
})

export default router
