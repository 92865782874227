export default {
	error: {
		require: 'Необходимый',
		invalidEmail: 'Неправильный формат электронной почты',
	},
	home: {
		title: 'Dashboard',
	},
	login: {
		title: "Вход на канал",
		submitButtonText: "Авторизоваться",
		label: { 
			email: 'Email',
			username: 'Имя пользователя',
			password: 'Пароль',
			vcode: 'Проверочный код'
		},
		placeholder: {
			email: 'Email',
			username: 'Имя пользователя',
			password: 'Пароль',
			vcode: 'Проверочный код'
		},
		forgot: {
			title: 'Забыли пароль',
			buttonText: 'Получить пароль'
		},
		register: {
			title: 'зарегистрироваться',
			buttonText: 'Отправить регистрацию'
		}
	}
}