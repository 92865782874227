import router from './index.js'
import { lang } from '@/utils/index.js'

/* 引入进度条插件 */
import NProgress from 'nprogress'
/* 引用进度条插件样式*/
import 'nprogress/nprogress.css'

// 路由白名单
const whiteList = ['/error', '/login']

import { useAuthStore } from '@/stores/index.js'

router.beforeEach((to, from, next) => {
	// 进度条开始
	NProgress.start()
	
	// 在白名单中的路由 或者 已经授权 直接进入
	if(whiteList.indexOf(to.path) >= 0) {
	    next()
	    return
	}
	
	const auth = useAuthStore()
	
	if(!auth.isAuthenticated) {
		return next({ path: '/login' })
	}
	
	//console.log(to, from)
	
	next()
})

router.afterEach((to) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
	    document.title = `${lang(to.meta.title)} - ${import.meta.env.VITE_APP_TITLE}`
	}
    // 进度条结束
    NProgress.done()
})
