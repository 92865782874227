export default {
	error: {
		require: 'Required',
		invalidEmail: 'Incorrect Email Format',
	},
	home: {
		title: 'Панель инструментов',
	},
	login: {
		title: "Channel Login",
		submitButtonText: "Login",
		label: {
			email: 'Email',
			username: 'Username',
			password: 'Password',
			vcode: 'Verification code'
		},
		placeholder: {
			email: 'Email',
			username: 'Username',
			password: 'Password',
			vcode: 'Verification code'
		},
		forgot: {
			title: 'Forgot Password',
			buttonText: 'Retrieve'
		},
		register: {
			title: 'Register',
			buttonText: 'Submit'
		}
	}
}