<template>
	<el-watermark content="">
		<el-container class="page">
			<el-aside class="page__aside" :class="sidebarClass">
				<LayoutAside :collapsed="collapsed" :data="menuList"></LayoutAside>
			</el-aside>
			<el-container>
				<el-header class="page__header">
					<TopBar :collapsed="collapsed" @menuSwitch="handleMenuSwitch"></TopBar>
				</el-header> 
				<el-main class="page__main">
					<Main></Main>
				</el-main>  
			</el-container>
		</el-container>
	</el-watermark>
</template>

<script setup>
	import { isUndefined } from 'lodash'
	
	import TopBar from './components/TopBar.vue'
	import TabMain from './components/TabMain.vue'
	import Main from './components/Main.vue'
	import LayoutAside from './components/Aside.vue'
	
	const sidebarClass = ref('sidebar_expanded')
	 
	import { useMenuList } from '@/router/menuList.js'
	 
	const menuList = ref([])
	  
	menuList.value = useMenuList().filter(menu => {
		// 过滤独立页面
		return isUndefined(menu.single) || !menu.single
	})
	
	const handleSidebarChange = (v) => {
		
	}
	const collapsed = ref(false)
	const handleMenuSwitch = () => {
		collapsed.value = !collapsed.value
		sidebarClass.value = collapsed.value ? 'sidebar_collapsed':'sidebar_expanded'
	}
</script>

<style lang="scss" scoped>
	.page {
		.page__header {
			box-shadow: 0 0px 5px 1px rgba(0,0,0,0.1);
			z-index: 1;
		}
		.page__aside {
			background: var(--el-aside-bg-color);
			color: var(--vt-c-white);
			z-index: 1;
		}
		.page__main {
			background-color: #F9F9F9;
			height: calc(100vh - var(--el-header-height));
			
			.page__main-content {
				padding: 20px;
			}
		}
	}
</style>