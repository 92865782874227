<template>
	<el-menu class="main-menu"
		:collapse="collapsed" 
		:collapse-transition="false" 
		router
		unique-opened
		:default-active="defaultActive"
		:default-openeds="[defaultActive]">
		<template v-for="item in data">
			<template v-if="item['children']">
				<MenuItem 
					:name="item['name']"
					:path="item['path']" 
					:icon="item['icon']"
					:title="item['title']"
					:data="item['children']">
				</MenuItem>
			</template>
			<template v-else>
				<el-menu-item :index="item['path']" :route="item['path']">
					<el-icon v-if="item['icon']">
						<component :is="item['icon']"></component>
					</el-icon>
					<span>{{ lang(item['title']) }}</span>
				</el-menu-item>
			</template>
		</template>
	</el-menu>
</template>

<script setup>
	import { lang } from '@/utils/index.js'
	
	import MenuItem from './MenuItem.vue'
	
	const props = defineProps({
		data: { type: Array, default: () => ([]) },
		collapsed: { type: Boolean, default: false }
	})
	
	const route = useRoute()
	
	const defaultActive = ref('/home')
	
	watch(route, (r) => { 
		defaultActive.value = r.path
	}, { immediate: true })
	
</script>

<style lang="scss" scoped>
	
</style>