<template>
	<el-scrollbar>
		<div class="page__main-content">
			<router-view v-slot="{ Component, route }">
				<keep-alive v-if="route.meta.keepAlive">
					<component :is="Component" :key="route.name"></component>
				</keep-alive>
				<component :is="Component" :key="route.name" v-else></component>
			</router-view>
		</div>
	</el-scrollbar>
</template>

<script setup>
</script>

<style lang="scss" scoped>
	.page__main-content {
		margin: 20px;
	}
</style>