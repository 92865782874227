import i18n from '@/plugins/i18n.js'

export const lang = (title) => {
	if(/^\{.*?\}$/.test(title)) {
		const key = title.substr(1, title.length - 2)
		return i18n.global.t(key)
	}
	
	return title
}

export const langKey = (key) => {
	return i18n.global.t(key)
}