import { defineStore } from 'pinia'
import { useStorage } from '@/utils/storage.js'

export const useStore = defineStore('default', () => {
	
})


export const useLangStore = defineStore('lang', () => {
	const langKey = '__lang__'
	const storage = useStorage('session')
	
	function getLang() {
		const lang = storage.get(langKey)
		if(!lang) {
			storage.set(langKey, lang)
		}
		
		return storage.get(langKey)
	}
	
	function setLang(currentLang) {
		storage.set(langKey, currentLang)
	}
	
	return {
		getLang, setLang
	}
})


export const useAuthStore = defineStore('auth', {
	state: () => {
		return { isAuthenticated: false, user: {} }
	},
	getters: {
		getAuthenticated: (state) => state.isAuthenticated,
		getUser: (state) => state.user
	},
	actions: {
		setAuth(isAuth) {
			this.isAuthenticated = !!isAuth
		},
		setUser(user) {
			this.user = user || {}
		}
	}
})