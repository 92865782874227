<template>
	<el-config-provider :size="size" :z-index="zIndex" :locale="locale">
		<slot></slot>
	</el-config-provider>
</template>

<script setup>
	import i18n from '@/plugins/i18n'
	
	import zhCn from 'element-plus/es/locale/lang/zh-cn'
	import enUs from 'element-plus/es/locale/lang/en'
	import ruRu from 'element-plus/es/locale/lang/ru'
	
	import { useLangStore } from '@/stores/index.js'
	
	const locale = computed(() => {
		switch(i18n.global.locale.value) {
			case 'zh': return zhCn
			case 'en': return enUs
			case 'ru': return ruRu
		}
	})
	const size = ref('default')
	const zIndex = ref(2000)
	
	
</script>

<style lang="scss" scoped>
</style>