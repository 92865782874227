import { useMessage } from '@/utils/message.js'

// vue-clipboard3 提供的 composition api
import useClipboard from "vue-clipboard3";

const { toClipboard } = useClipboard();

const checkPermission = (value, callback) => {
	const permissions = store.getters && store.getters.permissions
	
	if (Array.isArray(value) && value.length > 0) {
		const hasPermission = value.some(v => {
			return permissions.indexOf(v) >= 0
		})
		
		return callback && callback.call(this, hasPermission)
	}
	
	// 如果 permissions 不是数组，或者含有 *.*.* 那就是有全部权限 
	callback && callback.call(this, permissions.indexOf('*') >= 0)
}

export default {
	
	install(app) {
		
		// 用于防止多次点击保存效果，仅用于button
		app.directive('preventReClick', (el, binding) => {
			const preventReClickFun = (elValue, bindingValue) => {
				if (!elValue.disabled) {
					elValue.disabled = true
					setTimeout(() => {
						elValue.disabled = false
					}, bindingValue.value || 500)
				}
			}
			
			el.addEventListener('click', () => preventReClickFun(el, binding))
			
			binding.dir.unmounted = () => {
				el.removeEventListener('click', () => preventReClickFun(el, binding))
			}
		});
		
		// 如果无权限，隐藏
		app.directive('permi', (el, binding, vnode) => {
			checkPermission(el, binding, vnode, (hasPermi) => {
				if(hasPermi) {
					return
				}
				el.parentNode && el.parentNode.removeChild(el);
			})
		})

		// 如果无权限，置灰
		app.directive('hasPermi', (el, binding, vnode) => {
			checkPermission(el, binding, vnode, (hasPermi) => {
				if(hasPermi) {
					return
				}
				el.disabled = true
				el.setAttribute('class', el.getAttribute('class') + ' is-disabled')
				el.setAttribute('aria-disabled', true) 
			})
		})

		app.directive('copy', {
			mounted: (el, binding, vnode) => {
				el.copyToClipboard = () => {
					toClipboard(binding.value).then(result => {
		                message('复制成功!').show('success');
		            }).catch(err => {
		                message('复制失败!').show('warning')
		            });
				}
				
				el.style.cursor = 'pointer';
				el.addEventListener("click", el.copyToClipboard);
			},
			
			unmounted: (el) => {
				el.removeEventListener("click", el.copyToClipboard);
			}
		})
	}
	
}