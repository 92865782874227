import { createI18n } from 'vue-i18n'
import { useStorage } from '@/utils/storage.js'

import zhCn from '@/locales/zh-cn.js'
import ruRu from '@/locales/ru-ru.js'
import enUs from '@/locales/en-us.js'

const storage = useStorage('session')

const i18n = createI18n({
	locale: storage.get('__lang__') || 'zh-cn', // set default locale
	fallbackLocale: 'zh-cn',
	globalInjection: true,
	legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
	messages: {
		"zh-cn": zhCn,
		"ru-ru": ruRu,
		"en-us": enUs
	}, // set locale messages
});
 
export default i18n;